import React from 'react';
import '../assets/css/Top.css';

export default function Top() {
  return (
    <div className="container">
      <h2>Dyanmic Form</h2>
      <p>Click on the button below to add more input boxes</p>
    </div>
  );
}
