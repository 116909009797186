import React from 'react';

export default function Contact() {
  return (
    <>
      <div className="container">
        <h1>Terms and Conditions</h1>
      </div>
    </>
  );
}
